<template>
  <PullRefresh v-model="refresh" @refresh="onRefresh" pulling-text="Pull down to refresh..."
    loosing-text="Release to refresh..." loading-text="loading..." success-text="Loading completed" class="wrap">
    <div class="content_top">
      <div class="top" :class="lan == 'ar' ? 'flex_reverse' : ''">
        <img :src="require('@/assets/new/logo.png')" alt="">
        <div class="right_bar">
          <template>
            <Badge @click.stop="$router.push('/Message')" class="right" :color="'var(--btnsizeColor)'"
              v-if="!!unreadNum">
              <div class="text" slot="content" :style="{ color: verConfig.unreadColor || '' }">
                {{ unreadNum }}
              </div>
              <i class="iconfont icon-gl-envelope2" style="color: var(--btnsizeColor);"
                @click.stop="$router.push('/Message')"></i>
            </Badge>
            <div class="right" v-else>
              <i class="iconfont icon-gl-envelope2" style="color: var(--btnsizeColor);"
                @click="$router.push('/Message')"></i>
            </div>
          </template>
          <!-- <img @click="themeClick" :src="require('@/assets/ico/icon_' + theme + '.png')" alt=""> -->
          <div class="balance" v-if="verConfig.homeShowBalanceAtTop">
            {{ $money(userInfo.money || 0) }}
          </div>
        </div>
      </div>
    </div>
    <div class="top_bar">
      <div class="userinfo_bar">
        <div class="left" :class="lan == 'ar' ? 'flex_reverse' : ''">
          <VanImage width="60" height="60" :src="getImageUrl(userInfo.userimg)" round
            @click="() => $router.push('/Portrait')">
            <VanImage slot="error" width="60" height="60" :src="require('@/assets/Img/myImg/MyOne.png')" round />
          </VanImage>
          <div class="info" v-if="verConfig.homeShowInvite">
            <div class="nick_name" :style="theme == 'light' ? { color: 'var(--sizeColor)' } : {}">
              {{ userInfo.username || $t("newAdd.noLoginUserName") }}
            </div>
            <div class="invite_code" :style="theme == 'light' ? { color: 'var(--sizeColor)' } : {}">
              {{ `${$t("mine.invitationCode")}: ${userInfo.id || ""}` }}
            </div>
          </div>
          <div class="info" v-else>
            <div class="nick_name">
              {{ userInfo.username || $t("newAdd.noLoginUserName") }}
            </div>
            <div class="id">
              {{ `ID: ${userInfo.id || ""}` }}
            </div>
            <div class="level">{{ userInfo.rank_title || "" }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="userinfo_bar">
        <div class="left">
          <VanImage width="60" height="60" :src="getImageUrl(userInfo.userimg)" round
            @click="() => $router.push('/Portrait')">
            <VanImage slot="error" width="60" height="60" :src="require('@/assets/Img/myImg/MyOne.png')" round />
          </VanImage>
          <div class="info" v-if="verConfig.homeShowInvite">
            <div class="nick_name" :style="theme == 'light' ? { color: 'var(--sizeColor)' } : {}">
              {{ userInfo.username || $t("newAdd.noLoginUserName") }}
            </div>
            <div class="invite_code" :style="theme == 'light' ? { color: 'var(--sizeColor)' } : {}">
              {{ `${$t("mine.invitationCode")}: ${userInfo.id || ""}` }}
            </div>
          </div>
          <div class="info" v-else>
            <div class="nick_name">
              {{ userInfo.username || $t("newAdd.noLoginUserName") }}
            </div>
            <div class="id">
              {{ `ID: ${userInfo.id || ""}` }}
            </div>
            <div class="level">{{ userInfo.rank_title || "" }}</div>
          </div>
        </div>
        <div class="right_bar">
          <template>
            <Badge @click.stop="$router.push('/Message')" class="right" :color="verConfig.unreadBg || '#00adff'"
              v-if="!!unreadNum">
              <div class="text" slot="content" :style="{ color: verConfig.unreadColor || '' }">
                {{ unreadNum }}
              </div>
              <i class="iconfont icon-gl-envelope2" style="color: var(--theme);"
                @click.stop="$router.push('/Message')"></i>
            </Badge>
            <div class="right" v-else>
              <i class="iconfont icon-gl-envelope2" style="color: var(--theme);" @click="$router.push('/Message')"></i>
            </div>
          </template>
          <img @click="themeClick" :src="require('@/assets/ico/icon_' + theme + '.png')" alt="">
          <div class="balance" v-if="verConfig.homeShowBalanceAtTop">
            {{ $money(userInfo.money || 0) }}
          </div>
        </div>
      </div> -->
      <keep-alive>
        <div class="banner_bar">
          <Banner />
        </div>
      </keep-alive>
      <keep-alive>
        <div class="notice_bar">
          <Notice />
        </div>
      </keep-alive>
    </div>
    <div class="custdown">
      <div class="custdown_top">
        <div class="title">{{ $t('new_goods.custdown') }}</div>
        <div class="times">
          <div class="times_text">
            <div class="times_num">{{ forTime()[0] }}</div>
            <div class="num_text">{{ $t('new_goods.hours') }}</div>
          </div>
          <div class="times_text">
            <div class="num_icon">:</div>
            <div class="num_text"></div>
          </div>
          <div class="times_text">
            <div class="times_num">{{ forTime()[1] }}</div>
            <div class="num_text">{{ $t('new_goods.minutes') }}</div>
          </div>
          <div class="times_text">
            <div class="num_icon">:</div>
            <div class="num_text"></div>
          </div>
          <div class="times_text">
            <div class="times_num">{{ forTime()[2] }}</div>
            <div class="num_text">{{ $t('new_goods.seconds') }}</div>
          </div>
        </div>
      </div>
      <div class="custdown_cont">
        <template v-if="custList.length">
          <div class="cont_list" v-for="item, index in custList" :key="index">
            <div class="list_cont">
              <img :src="$img(item.picture[0])" alt="">
              <span>{{ item.title }}</span>
              <div class="list_btns">
                <div class="btn">{{ item.desc }}</div>
                <div class="btn task_btn" @click="taskClick(item.url)">{{ $t('new_goods.taskBtn') }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no_list">
            {{ custNolist }}
          </div>
        </template>
      </div>
    </div>
    <div class="contents">
      <div class="title">{{ $t('gift.away') }}</div>
      <template v-if="mygift.length">
        <van-tabs v-model="activeName">
          <van-tab :name="index" v-for="item, index in mygift" @click="mylistClick">
            <template #title>
              <div class="list_img">
                <img :src="item && item.product_picture && $img(item.product_picture[0])" alt="">
              </div>
              <span class="list_title">{{ item.product_name }}</span>
              <div class="list_money">x{{ item.amount - item.gift_amount }}</div>
            </template>
          </van-tab>
        </van-tabs>
        <div class="btn" @click="overlayShow = true">
          {{ $t('gift.away') }}
        </div>
      </template>
      <!-- <div class="lists" v-else>
        <div class="nodata">{{ $t('gift.overlay.no_data') }}</div>
      </div> -->

      <div class="gift_list">
        <div class="scroll-container" ref="container">
          <marquee behavior="scroll" class="content" direction="up" scrollamount="3">
            <div class="content_list" :class="lan == 'ar' ? 'flex_reverse' : ''" v-for="item, index in giftArr"
              :key="index">
              <div class="img">
                <VanImage width="24" height="24" :src="$img(item.userimg)" round>
                  <VanImage slot="error" width="24" height="24" :src="require('@/assets/Img/myImg/MyOne.png')" round />
                </VanImage>
              </div>
              <div class="username">{{ item.username }}</div>
              <div class="send" :style="lan == 'ar' ? { marginLeft: 'calc(15rem / 16)' } : {}">{{ $t('chat.room.send') }}
              </div>
              <div class="">{{ item.product_name }}&nbsp;x{{ item.num }}</div>
              <div class=""></div>
              <img :src="$img(item.product_picture[0])" alt="">
            </div>
          </marquee>
        </div>
      </div>
    </div>
    <div class="contents">
      <div class="title">{{ $t("Home.WithdrawSu.Balance") }}</div>
      <div class="money">{{ userInfo.money }}</div>
      <div class="list">
        <div class="items" v-for="item, index in tools" :key="index" @click="item.func">
          <img :src="item.img" alt="">
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <van-overlay :show="overlayShow" @click="closeOverlay" z-index="9999">
      <div class="wrappers" @click.stop>
        <div class="wrappers_title">{{ $t('gift.away') }}</div>
        <div class="block">
          <div class="block_img">
            <div class="imgs">
              <img :src="sendItem && sendItem.product_picture && $img(sendItem.product_picture[0])" alt="">
              <div class="title">{{ sendItem && sendItem.product_name }}</div>
              <div class="amount">x{{ amount }}</div>
            </div>
          </div>
          <div class="block_cont" style="margin: calc(6rem / 16) 0;">
            <div class="cont_left">{{ $t('gift.overlay.num') }}:</div>
            <div class="cont_value">
              <van-stepper v-model="amount" :max="sendItem && (sendItem.amount - sendItem.gift_amount)" integer
                theme="round" button-size="22" />
            </div>
          </div>
          <div class="block_cont">
            <div class="cont_left">{{ $t('gift.overlay.room') }}:</div>
            <div class="cont_value">
              <input v-model="remark" class="input" type="text" :placeholder="$t('gift.overlay.inputPlaceholder')">
            </div>
          </div>
          <div class="btn" :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : { }"
            @click="handleSend">
            {{ $t('gift.away') }}
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- <div class="partner_bar">
      <div class="title_bar">{{ $t("home.title.patener") }}</div>
      <div class="imgs_wrap">
        <img v-for="(item, index) in imgs_wrap" :key="index"
          :src="require('@/assets/home/patener/' + item.img + '_' + theme + '.png')" alt="" />
      </div>
    </div> -->
    <div class="wrap_old">
      <div class="wrapper" v-if="contentShow && !!noticeContent">
        <div class="content_wrapper" @click="() => { }">
          <div class="title">{{ $t("home.title.notice") }}</div>
          <div class="content" v-html="noticeContent"></div>
          <div class="ok" @click="contentShow = false">
            {{ $t("Button.confirm") }}
          </div>
        </div>
      </div>
    </div>
  </PullRefresh>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import LoadingRE from "@/components/LoadingRE";
import VueClipboard from "vue-clipboard2";
import { Toast } from "vant";
import Cookies from "js-cookie";
import { GET_CONFIG_INFO, SHOP_LIST, MY_GIFT, GETGIFT_LIST, SEND_GIFT } from "@/api";
import {
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Overlay,
  PullRefresh,
  Icon,
  Image as VanImage,
  Badge,
  Tab,
  Tabs,
  Stepper
} from "vant";
import dataDetail from "@/utils/dataDetail";
import { $get } from "../../utils/request";
import verConfig from "@/utils/verConfig";
import Vue from "vue";
import getRandNoticeData from "@/utils/randNoticeData";
import Br from "../../components/br.vue";
import Banner from "@/components/banner.vue";
import Notice from "@/components/homeNotice.vue";
import { mapGetters } from "vuex";
import HallMarquee from "@/components/hallMarquee.vue";
export default {
  components: {
    Tabbar,
    LoadingRE,
    VueClipboard,
    Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    PullRefresh,
    Br,
    Icon,
    Banner,
    VanImage,
    Badge,
    Notice,
    HallMarquee,
    [Icon.name]: Icon,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Overlay.name]: Overlay,
    [Stepper.name]: Stepper
  },
  data() {
    this.tools = [
      // {
      //   // img: require("@/assets/home/wallet.png"),
      //   img: require("@/assets/home/new/wallet.png"),
      //   title: this.$t("home.btn.wallet"),
      //   func: () => {
      //     this.$router.push("Financial");
      //   },
      // },
      {
        // img: require("@/assets/home/recharge.png"),
        img: require("@/assets/new/index/deposit.png"),
        title: this.$t("home.btn.recharge"),
        func: () => {
          this.toRecharge();
        },
      },
      {
        // img: require("@/assets/home/withdraw.png"),
        img: require("@/assets/new/index/withdraw.png"),
        title: this.$t("home.btn.withdraw"),
        func: () => {
          this.$router.push("new-withdraw");
        },
      },
      {
        // img: require("@/assets/home/withdraw.png"),
        img: require("@/assets/new/index/integral.png"),
        title: this.$t("mine.wtdAmt"),
        func: () => {
          this.$router.push("AccountData?type=point");
        },
      },
      {
        img: require("@/assets/new/index/p2p.png"),
        title: this.$t("newTab.p2p"),
        func: () => {
          this.$router.push('p2p')
        }
      },
      {
        img: require("@/assets/new/index/aboutus.png"),
        title: this.$t("mine.serviceTools.aboutUs"),
        func: () => {
          this.$router.push('AboutUs')
        }
      },
      // {
      //   img: require("@/assets/home/invite.png"),
      //   title: this.$t("home.btn.invite"),
      //   func: () => {
      //     this.$router.push("inviteFriends");
      //   },
      // },
      // {
      //   // img: require("@/assets/home/message.png"),
      //   img: require("@/assets/home/new/message.png"),
      //   title: this.$t("home.btn.message"),
      //   showMsg: true,
      //   func: () => {
      //     this.$router.push("/Message");
      //   },
      // },
    ];
    this.company = [
      {
        icon: 'bell',
        title: this.$t("home.title.notice"),
        pagePath: "Notice",
      },
      {
        icon: 'icon-gongsiguanli1',
        title: this.$t("mine.serviceTools.aboutUs"),
        pagePath: "AboutUs",
      },
    ];
    this.company2 = [
      {
        // img: require("@/assets/home/P2P.png"),
        icon: 'icon-zhuanqian',
        title: this.$t("newTab.p2p"),
        pagePath: "p2p",
      },
      {
        // img: require("@/assets/home/order.png"),
        icon: 'icon-complete-fill',
        title: this.$t("newTab.order"),
        pagePath: "cart",
      },
    ]
    this.imgs_wrap = [
      { img: "TikTok" },
      { img: "walmart" },
      { img: "lazada" },
      { img: "tether" },
      { img: "binance" },
      { img: "paypal" },
      { img: "flipkart" },
      { img: "google" },
      { img: "phonePe" },
      { img: "sbi" },
      { img: "amazon" },
      { img: "paytm" },
      { img: "uberEats" },
      { img: "shopee" },
      { img: "ebay" },
      { img: "zomato" },
    ];
    this.levelsBGs = [
      ["#fafced", "#fcf7d4"],
      ["#fcf9ed", "#fef1c2"],
      ["#fcfaed", "#fceccb"],
      ["#fcf9ed", "#fce8cc"],
      ["#fcf7ed", "#fcdfcb"],
      ["#fcf3ed", "#fcd8cb"],
    ];
    return {
      overlayShow: false,
      remark: '',
      activeName: 0,
      welfareDialogContent: "",
      welfareDialogShow: false,
      refresh: false,
      unreadNum: 0,
      verConfig,
      islocalImg: true,
      // userInfo: {
      //   money: 0,
      //   frozen_price: 0,
      //   complete_total_price: 0,
      //   complete_yesterday_price: 0,
      //   frozen_rate_price: 0,
      // },
      country: Cookies.get("language"),
      bannerArr: [],
      noticeArr: [],
      inimenuArr: [
        {
          // title: 'Mission hall',
          title: this.$t("newAdd.missionHall"),
          img: require("../../assets/icon/1.png"),
          activeImg: require("../../assets/icon/1-active.png"),
          // value: 1,
        },
        {
          // title: 'Help center',
          title: this.$t("newAdd.helpCenter"),
          img: require("../../assets/icon/3.png"),
          activeImg: require("../../assets/icon/3-active.png"),
          // value: 2,
        },
        {
          // title: 'Invite friends',
          title: this.$t("newAdd.inviteFriends"),
          img: require("../../assets/icon/7.png"),
          activeImg: require("../../assets/icon/7-active.png"),
          // value: 3,
        },
      ],
      menuArr: [],
      noticeArrss: [],
      menuVal: 0,
      allowed: 0,
      contentShow: true,
      noticeContent: "",
      gradeArr: [
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
      ],
      videoArr: [
        {
          title: "video1",
        },
        {
          title: "video2",
        },
        {
          title: "video3",
        },
        {
          title: "video4",
        },
      ],
      helpData: [],
      activeNames: 0,
      service_url: "",
      loadingNum: 0,
      noticeShowIndex: 3,
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
      custList: [],
      Times: "00:00:00",
      timer: null,
      custNolist: '',
      mygift: [],
      giftArr: [],
      amount: 1
    };
  },
  computed: {
    ...mapGetters(["userInfo", "levels", "theme"]),
    levelsData() {
      if (this.verConfig.levelsReverse) {
        return this.levels.reverse();
      }
      return this.levels;
    },
    sendItem() {
      return this.mygift && this.mygift[this.activeName]
    },
  },
  beforeUnmount() {
    clearInterval(this.timer)
    this.timer = null;
  },
  methods: {
    handleSend() {
      this.sendGift(this.sendItem.id, this.amount)
    },
    async sendGift(id, amount) {
      Toast.loading();
      try {
        const res = await SEND_GIFT({
          id,
          amount,
          remark: this.remark
        });
        this.getMygift();
        this.amount = 1;
        Toast(res.msg);
        this.remark = ''
        this.overlayShow = false;
      } catch (err) {
        Toast(err.msg)
      }
    },
    closeOverlay() {
      this.overlayShow = false;
      this.account = 1;
      this.remark = ""
    },
    mylistClick(name) {
      console.log(name, this.activeName);
    },
    async getgiftList() {
      let _this = this;
      _this.loadingNum++;
      try {
        const res = await GETGIFT_LIST();
        _this.loadingNum--;
        _this.giftArr = res.data.lists
      } catch (error) {
        _this.loadingNum--;

      }
    },
    async getMygift() {
      let _this = this;
      _this.loadingNum++;
      try {
        const res = await MY_GIFT();
        _this.loadingNum--;
        _this.mygift = res.data.lists
      } catch (error) {
        _this.loadingNum--;
        console.error(error);
      }
    },
    descClick() {

    },
    taskClick(url) {
      window.location.href = url;
    },
    async getShop() {
      let _this = this;
      _this.loadingNum++;
      try {
        const res = await SHOP_LIST();
        _this.loadingNum--;
        _this.custNolist = res.msg
        if (res.data) {
          _this.custList = res.data.products;
          _this.getData(res.data)
          return;
        }
        _this.custList = []
      } catch (error) {
        _this.loadingNum--
        _this.custNolist = error.msg
        console.error(error);
      }
    },
    forTime() {
      return this.Times.split(':')
    },
    getData(data) {
      let times = data.end - data.current
      this.timer = setInterval(() => {
        times--
        this.Times = this.formatTime(times);
        if (times == 0) {
          clearInterval(this.timer)
          this.timer = null;
          this.getShop();
        }
      }, 1000)
    },
    formatTime(timestamp) {
      let hours = Math.floor(timestamp / 3600);
      let minutes = Math.floor((timestamp % 3600) / 60);
      let seconds = Math.floor(timestamp % 60);

      let formattedHours = hours.toString().padStart(2, '0');
      let formattedMinutes = minutes.toString().padStart(2, '0');
      let formattedSeconds = seconds.toString().padStart(2, '0');

      return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
    },
    themeClick() {
      this.$store.dispatch('setTheme', this.theme == 'dark' ? 'light' : 'dark')
    },
    levelItemBG(item, index) {
      const i = index % this.levelsBGs.length;
      const bgItem = this.levelsBGs[i];
      console.log(`linear-gradient(to right, ${bgItem[0]}, ${bgItem[1]});`);
      return `linear-gradient(to right, ${bgItem[0]}, ${bgItem[1]})`;
    },
    handleTo(item) {
      this.$router.push(item.pagePath);
    },
    getImageUrl(url) {
      const reg = new RegExp("http");
      if (reg.test(url)) return url;
      return this.verConfig.url + url;
    },
    // add:点击充值的按钮
    RechargeEvent() {
      this.$router.push(this.rechargePath);
    },
    // add 分享
    goshare() {
      this.$router.push("inviteFriends");
    },
    // add:点击提现按钮
    WithdrawEvent() {
      this.$router.push({ name: "newWithdraw" });
    },
    // add:去下载
    downloadEvent() {
      if (localStorage.getItem("android")) {
        location.href = localStorage.getItem("android");
      } else {
        location.href = "http://www.baidu.com";
      }
    },
    // add/随机名字
    randName() {
      let name =
        `+${this.verConfig.areaCode}\n` +
        String(dataDetail.rand(20, 99)) +
        dataDetail.rand(3, 9) +
        "****" +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10);
      return name;
    },
    // add/随机
    randMoney() {
      const { homeProfitIntervalMax, homeProfitInterval } = this.verConfig;
      let interval = homeProfitInterval || [100, 300000];

      if (homeProfitIntervalMax) {
        if (dataDetail.rand(0, 10) < 2) {
          interval = homeProfitIntervalMax;
        }
      }
      let probability = dataDetail.rand(0, 100);
      let res = 0;
      if (probability <= 30) {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[0], interval[1] / 20))
        // }
        return dataDetail.rand(interval[0], interval[1] / 20);
      } else {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[1] / 20, interval[1]))
        // }
        return dataDetail.rand(interval[1] / 20, interval[1]);
      }
    },
    moneyFormat(money) {
      const arr = money.toString().split("").reverse();
      for (let i = 0, len = arr.length; i < Math.floor(len / 3); i++) {
        arr.splice(3 * (i + 1) + i, 0, ".");
      }
      if (arr[arr.length - 1] === ".") {
        arr.pop();
      }
      return arr.reverse().join("");
    },
    // add:lunbo
    noticeIni() {
      for (let i = 0; i < 100; i++) {
        this.noticeArrss.push({
          name: this.randName(),
          money: this.randMoney(),
        });
      }
    },
    // add:lunbo
    noticeDataUpdate() {
      setInterval(() => {
        this.noticeArrss.splice(this.noticeShowIndex, 1, {
          name: this.randName(),
          money: this.randMoney(),
        });
        if (this.noticeShowIndex >= 4) {
          this.noticeShowIndex = 1;
        } else {
          this.noticeShowIndex++;
        }
      }, 3000);
    },
    // add
    changeIndex(index) {
      // if(index===4){
      //   this.$refs.swiper.swipeTo({
      //     index:0
      //   })
      // }
    },

    toInvite() {
      this.$router.push({ name: "InviteFriends" });
    },
    toRecharge() {
      if (this.ver == "amigo_vi") {
        this.$router.push("/RechargeDesc");
        return;
      }
      if (this.verConfig.pathNewRecharge)
        this.$router.push({ name: "thirdRechargeNew" });
      else this.$router.push({ name: "NewRecharge" });
    },
    toService() {
      // this.$api.toService();
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    toService() {
      // window.location.href = this.service_url
    },
    copySuccess() {
      // Toast('Copy success!')
      Toast(this.$t("newAdd.copySuccess"));
    },
    copyError() {
      // Toast('Copy the failure!')
      Toast(this.$t("newAdd.copyError"));
    },
    menuChange(val) {
      this.menuVal = val;
    },
    handleChange(val) { },
    // 获取等级数组
    getLevels() {
      this.$api.getLevel((res) => {
        this.loadingNum--;
        this.gradeArr = res;
      });
    },
    // 等级跳转
    toRushOrder(index) {
      this.$router.push({ name: "MakeMoney" });
      // this.$router.push({
      //   name: 'cart',
      //   params: {
      //     level: index,
      //   },
      // })
    },
    toUnlock() {
      this.$router.push({ name: "levelUpgrade" });
    },
    // getNotice(){
    //   this.$api.getNotice(res=>{
    //     this.noticeArr = (res.scroll_notice.split(","));
    //     this.service_url = res.service_url;
    //     this.$cookie.set('service_url',res.service_url);
    getNotice() {
      this.$api.getNotice((res) => {
        // this.noticeArr = res.scroll_notice.split(',')
        this.noticeArr.push(res.scroll_notice);
        this.service_url = res.service_url;
        localStorage.setItem("android", res.appurl_android);
        localStorage.setItem("service_url", res.service_url);
      });
    },
    getBanner() {
      this.loadingNum++;
      this.$api.getBanner(
        (res) => {
          this.loadingNum--;
          if (res.length) {
            this.bannerArr = res;
            this.islocalImg = false;
          }
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getConfigShare() {
      this.loadingNum++;
      GET_CONFIG_INFO({
        act: "share",
      })
        .then((r) => {
          this.loadingNum--;
          if (r.data.ret === 1) {
            this.inviteLink = r.data.data.text;
            this.$cookie.set("public-share-item", JSON.stringify(r.data.data));
          } else {
            this.inviteLink = "";
            this.$cookie.set("public-share-item", {});
          }
        })
        .catch((e) => {
          console.error(e);
          this.loadingNum--;
        });
    }, // 获取分享地址
    getMenuArr() {
      this.loadingNum++;
      this.$api.getHomeConfig(
        (res) => {
          this.loadingNum--;
          this.menuArr = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getHelpArr() {
      this.loadingNum++;
      this.$api.getHomeHelp(
        (res) => {
          this.loadingNum--;
          for (let i = 0; i < res.lists.length; i++) {
            let res_item = res.lists[i];
            res_item.content = res_item.content.replace(/<[^>]*>/gi, "");
          }
          this.helpData = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    async getNoticeContent() {
      this.loadingNum++;
      try {
        const res = await $get("/content/winnotice");
        this.loadingNum--;
        this.noticeContent = res.data.data[0].content;
      } catch (error) {
        this.loadingNum--;
      }
    },
    async getMessage() {
      this.loadingNum++;
      try {
        const res = await $get("/users/message", {
          perPage: 999,
          status: "unread",
        });
        const { ret, msg, data } = res.data;
        this.loadingNum--;
        this.unreadNum = data.total || 0;
        if (!data.total) return;
        if (!!data.lists.length) {
          this.welfareDialogShow = true;
          this.welfareDialogContent = data.lists[0].content;
        }
        // console.log(data.total, "消息")
      } catch (error) {
        this.loadingNum--;
      }
    },
    showGiftExpectedEarnings(c) {
      const { today_gift, yesterday_gift } = this.userInfo;
      if (!(today_gift + yesterday_gift)) return "0.00";
      return ((today_gift + yesterday_gift) * c).toFixed(2);
    },
    onRefresh() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.getLevels();
      this.getNotice();
      if (!this.verConfig.hideNotice) {
        this.getNoticeContent();
      }
      this.getBanner();
      this.getConfigShare();
      this.getMenuArr();
      this.getHelpArr();
      this.$store.dispatch("getUserInfo");
    },
  },
  created() {
    Toast.loading({
      forbidClick: true,
      duration: 0,
    });
    this.getLevels();
    this.getNotice();
    if (!this.verConfig.hideNotice) {
      this.getNoticeContent();
    }
    this.getConfigShare();
    this.getMenuArr();
    this.getHelpArr();
    this.getMessage();
    this.getShop();
    this.getMygift();
    this.getgiftList();
    this.$store.dispatch('getUserInfo')
  },
  mounted() {
    if (!this.$cookie.get("token")) {
      this.inimenuArr.splice(2, 1);
    }
    // this.noticeIni()
    this.noticeArrss = getRandNoticeData();
    // this.noticeDataUpdate();
  },
  destroyed() { },
  watch: {
    loadingNum: function (newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.refresh = false;
          Toast.clear();
        }, 1000);
      }
    },
  }
};
</script>

<style lang="less" scoped>
@Color: rgb(222, 140, 23);
@bg: var(--bg);
@sizeColor: var(--sizeColor);
@newBg: #ff004f;

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.spancolor {
  color: var(--color, @Color);
}

.wrap {
  min-height: 100vh;
  // background: #f6f8f9;
  background: var(--nav-background);

  .top_bar {
    // background: #0271dc;
    // background: var(--theme);
    background: var(--nav-background);
    padding: calc(13rem / 16);

    .userinfo_bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--bg);
      border-radius: calc(20rem / 16);
      padding: calc(16rem / 16);
      margin-top: calc((-60rem) / 16);

      .left {
        display: flex;
        flex: 1;

        .info {
          margin: 0 calc(16rem / 16);
          font-size: calc(16rem / 16);
          color: var(--theme);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }

      .right_bar {
        display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        // align-items: flex-end;
        align-items: center;

        .right {
          /deep/ .van-badge {
            border: none;
          }

          >.iconfont {
            color: #fff;
            font-size: calc(28rem / 16);
          }
        }

        img {
          width: calc(26rem / 16);
          height: calc(26rem / 16);
          margin-left: calc(10rem / 16);
        }

        .balance {
          color: #fff;
          margin-top: calc(10rem / 16);
        }
      }
    }

    .banner_bar {
      margin-top: calc(10rem / 16);
      border-radius: 10px;
      overflow: hidden;
    }

    .notice_bar {
      margin-top: calc(10rem / 16);
    }
  }

  .custdown {
    background-color: var(--bg);
    margin: calc(13rem / 16);
    margin-top: 0;
    border-radius: calc(6rem / 16);
    padding: calc(19rem / 16) calc(8rem / 16) calc(16rem / 16);

    &_top {
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-between;
      margin-bottom: calc(16rem / 16);


      .title {
        // color: var(--newsizeColor);
        color: @newBg;
        font-size: calc(16rem /16);
      }

      .times {
        display: flex;
        margin-top: calc(16rem / 16);

        .times_text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .times_num {
            display: flex;
            border-radius: calc(5rem / 16);
            font-size: calc(24rem / 16);
            padding: calc(10rem / 16);
            // background-color: var(--newsizeColor);
            background-color: @newBg;
            // color: var(--timeColor);
            color: var(--btnsizeColor);
            // align-items: center;
          }

          .num_icon {
            margin: 0 calc(6rem / 16);
            padding: calc(12rem / 16);
            // color: var(--newsizeColor);
            color: @newBg;
          }

          .num_text {
            font-size: calc(10rem / 16);
            margin-top: calc(10rem / 16);
            // color: var(--newsizeColor);
            color: @newBg;
          }
        }


      }
    }

    &_cont {
      display: flex;
      flex-wrap: wrap;
      gap: calc(16rem / 16);

      .cont_list {
        // padding-bottom: calc(16rem / 16);
        width: calc(50% - (8rem / 16));
        background-color: var(--nav-background);
        border-radius: calc(20rem / 16);
        overflow: hidden;


        .list_cont {
          padding: calc(8rem / 16) calc(8rem / 16) calc(16rem / 16);
          display: flex;
          flex-direction: column;
          height: calc(100% - (24rem / 16));

          img {
            width: 100%;
            // height: calc(80rem / 16);
          }


          span {
            color: var(--btnsizeColor);
            font-size: calc(12rem / 16);
            font-weight: bold;
          }

          .list_btns {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .btn {
              border-radius: calc(10rem / 16);
              font-size: calc(12rem / 16);
              color: var(--grayColor);
              padding: calc(8rem / 16) 0;
            }

            .task_btn {
              padding: calc(12rem / 16);
              text-align: center;
              background-color: var(--newBtn);
              color: var(--btnsizeColor);
              padding: calc(10rem / 16);
            }
          }
        }
      }
    }
  }

  .no_list {
    color: var(--newsizeColor);
    font-size: calc(14rem / 16);
    padding: calc(20rem / 16);
    text-align: center;
  }

  .balance_bar {
    margin: calc(13rem / 16);
    // background: #fff;
    background: @bg;
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: calc(60rem / 16);

    >.title {
      font-size: calc(16rem / 16);
      // color: #403f3f;
      color: var(--theme);
    }

    .value {
      // color: #0060bd;
      color: var(--sizeColor);
      font-size: calc(18rem / 16);
    }
  }

  .trade_bar {
    display: flex;
    margin: calc(13rem / 16);
    // margin-top: 0;
    justify-content: space-between;
    @gap: calc(15rem / 16);
    @pad: calc(32rem / 16);

    >div {
      width: calc((100% - @gap) / 2 - @pad);
      height: calc(60rem / 16);
      border-radius: 10px;
      // background-color: #fff;
      background-color: @bg;
      display: flex;
      // justify-content: space-around;
      justify-content: space-between;
      padding: 0 calc(16rem / 16);
      align-items: center;

      >.iconfont {
        font-size: calc(30rem / 16);
      }

      >.text {
        font-size: calc(14rem / 16);
        // color: #000;
      }
    }

    .recharge {
      // color: #955f00;
      color: var(--theme);
    }

    .withdraw {
      color: var(--theme) // color: #04672d;
    }
  }

  .about_bar {
    .trade_bar();
    // justify-content: space-around;
    @gap: calc(15rem / 16);
    @pad: calc(32rem / 16);

    >div {
      border-radius: 6px;
      width: calc((100% - @gap) / 2);
      justify-content: flex-end;
      align-items: center;
      background-size: 100% 100%;
      padding: 0;

      >.title {
        font-size: calc(13rem / 16);
        padding: calc(6rem / 16) calc(8rem / 16);
        padding-left: calc(12rem / 16);
        // background-color: #0271dc;
        // background-color: var(--nav-background);
        color: var(--theme);
        // color: #fff;
        border-top-left-radius: 90px;
        border-bottom-left-radius: 90px;
      }
    }

    .item_bar {
      justify-content: center;
    }

    .about_item {
      background-color: @bg;
      color: var(--theme);
      display: flex;
      align-items: center;
      font-size: calc(14rem / 16);
      justify-content: center;
      height: calc(40rem / 16);
      font-weight: 500;
    }
  }

  .levels_bar {
    padding: calc(13rem / 16);
    border-radius: calc(8rem / 16);
    background: #fff;
    overflow: hidden;

    .level_item {
      position: relative;
      background: var(--theme);
      margin: calc(10rem / 16) 0;
      border-radius: calc(8rem / 16);
      height: calc(60rem / 16);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 calc(6rem / 16) 0 calc(10rem / 16);
      color: #592b01;

      .level_titile {
        font-weight: 600;
      }

      .current {
        position: absolute;
        width: calc(30rem / 16);
        left: 0;
        top: 0;
      }

      >div {
        text-align: center;

        .text {
          font-size: calc(12rem / 16);
        }

        .num {
          font-size: calc(14rem / 16);
          margin-top: calc(6rem / 16);
          font-weight: 600;
        }
      }

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  .hall_bar {
    margin: calc(13rem / 16);
    // margin-top: 0;
    // background: #fff;
    background: @bg;
    border-radius: 10px;
    overflow: hidden;

    .left_triangle(@size: 10px) {
      border: @size solid transparent;
      border-right-width: calc(@size * 1.8);
      border-right-color: var(--moduleColor);
    }

    .right_triangle(@size: 10px) {
      border: @size solid transparent;
      border-left-width: calc(@size * 1.8);
      border-left-color: var(--moduleColor);
    }

    .title_bar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: calc(8rem / 16);
      color: var(--moduleColor);

      .left {
        display: flex;
        align-items: center;

        .triangle1 {
          .left_triangle(calc(3rem / 16));
        }

        .triangle2 {
          .left_triangle(calc(4rem / 16));
        }

        .triangle3 {
          .left_triangle(calc(6rem / 16));
        }
      }

      .right {
        .left();

        .triangle1 {
          .right_triangle(calc(6rem / 16));
        }

        .triangle2 {
          .right_triangle(calc(4rem / 16));
        }

        .triangle3 {
          .right_triangle(calc(3rem / 16));
        }
      }

      .title {
        font-size: calc(20rem / 16);
        // font-family: PingFangSC-Medium;
      }
    }

    .hr {
      width: 90%;
      height: 1px;
      background-color: #0056a8;
      margin: 0 auto;
      margin-bottom: calc(5rem / 16);
    }

    marquee {

      // height: calc(208rem / 16);
      .item {
        margin: calc(10rem / 16) calc(4rem / 16) 0;
        padding: calc(7rem / 16) calc(5rem / 16);
        padding-right: calc(14rem / 16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: calc(15rem / 16);
        // background-color: #f6f8f9;
        background-color: var(--marqueeColor);

        .left {
          display: flex;
          font-size: calc(14rem / 16);

          .info {
            margin-left: calc(4rem / 16);
            padding: 1px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .nick_name {
              // color: #5300b1;
              // color: var(--theme);
              // color: #666;
              color: var(--marqueeItemColor);
            }

            .text {
              letter-spacing: 0;
              color: var(--marqueeItemColor);
            }
          }
        }

        .right {
          // color: #0f6b00;
          color: var(--theme);
          font-size: calc(14rem / 16);
          letter-spacing: 0;
          // font-family: PingFangSC-Medium;
        }
      }
    }
  }

  .partner_bar {
    margin: 0 calc(13rem / 16);
    margin-bottom: calc(20rem / 16);

    .title_bar {
      margin-bottom: calc(8rem / 16);
      border-radius: calc(10rem / 16);
      // background: #fff;
      background: @bg;
      color: var(--moduleColor);
      line-height: calc(50rem / 16);
      text-align: center;
      // font-family: PingFangSC-Medium;
      font-size: calc(16rem / 16);
      font-weight: 600;
    }

    .imgs_wrap {
      display: flex;
      @gap: calc(12rem / 16);
      flex-wrap: wrap;

      >img {
        width: calc((100% - @gap * 2) / 3);
        border-radius: calc(10rem / 16);
        background-color: @bg;
        height: calc(90rem / 16);
        margin-top: @gap;

        &:nth-child(1) {
          margin-top: 0;
        }

        &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(3) {
          margin-top: 0;
        }

        &:nth-child(3n - 1) {
          margin-left: @gap;
          margin-right: @gap;
        }
      }
    }
  }

  .wrapper {
    // width: 100vw;
    padding: 0 calc(27rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;
    // height: calc(100vh - 50px);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100vw - calc(54rem / 16));
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    .content_wrapper {
      // width: calc(100vw - calc(27rem / 16) * 2);
      width: 100%;
      max-height: 60vh;
      // height: calc(525rem / 16);
      background: var(--bg);
      padding: calc(11rem / 16) calc(18rem / 16);
      border-radius: calc(10rem / 16);
      position: relative;

      .content {
        max-height: calc(60vh - calc(100rem / 16));
        overflow-y: scroll;
        color: var(--btnsizeColor);
      }
      .content::-webkit-scrollbar{
        display: none;
      }

      .title {
        color: var(--btnsizeColor);
        line-height: calc(27rem / 16);
        font-weight: 600;
        font-size: calc(20rem / 16);
        text-align: center;
      }

      .ok {
        width: calc(138rem / 16);
        height: calc(46rem / 16);
        border-radius: calc(23rem / 16);
        background: var(--newBtn);
        color: var(--btnsizeColor);
        margin: calc(11rem / 16) auto 0;
        text-align: center;
        font-size: calc(15rem / 16);
        line-height: calc(46rem / 16);
      }
    }
  }

  @grey: #f5f4f7;

  .download {
    background-color: #f5f4f7;
    padding: 5vw 0;

    .download_content {
      .flex(space-between);
      width: 85vw;
      margin: 0 auto;
      background-color: #fff;
      box-shadow: 0 0 2.667vw rgba(0, 0, 0, 0.05);
      border-radius: 2.667vw;
      padding: 3vw;

      img {
        width: 30px;
      }
    }
  }

  .content_top {
    background: url('../../assets/new/top_bg.png') no-repeat;
    height: calc(120rem / 16);
    background-size: 100% 100%;

    .top {
      padding: calc(16rem / 16) calc(13rem / 16);
      display: flex;
      justify-content: space-between;

      img {
        height: calc(28rem / 16);
      }

      .right_bar {
        display: flex;
        align-items: center;

        .right {
          /deep/ .van-badge {
            border: none;
          }

          >.iconfont {
            color: #fff;
            font-size: calc(28rem / 16);
          }
        }

        img {
          width: calc(26rem / 16);
          height: calc(26rem / 16);
          margin-left: calc(10rem / 16);
        }

        .balance {
          color: #fff;
          margin-top: calc(10rem / 16);
        }
      }
    }
  }

  .contents {
    margin: calc(13rem / 16);
    background-color: var(--bg);
    border-radius: calc(6rem / 16);
    padding: calc(16rem / 16);

    .title {
      color: var(--btnsizeColor);
      font-size: calc(14rem / 16);
      text-align: center;
    }

    .money {
      font-size: calc(20rem / 16);
      color: var(--newsizeColor);
      margin-top: calc(8rem / 16);
      text-align: center;
    }

    .list {
      display: flex;
      justify-content: space-between;
      margin-top: calc(24rem / 16);

      .items {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: calc(24rem / 16);
          height: calc(24rem / 16);
          display: block;
        }

        span {
          font-size: calc(10rem / 16);
          color: var(--btnsizeColor);
          display: inline-block;
          margin-top: calc(8rem / 16);
        }
      }
    }

    /deep/ .van-tabs {
      margin-top: calc(12rem / 16);
    }

    /deep/ .van-tabs__wrap {
      height: auto;
    }

    /deep/ .van-tabs__nav {
      padding: 0;
      background-color: transparent;
    }

    /deep/ .van-tab {
      padding: 0;
    }

    /deep/ .van-tabs__line {
      display: none;
    }

    /deep/ .van-tab--active {
      border: 1px solid var(--newsizeColor);
      border-radius: calc(10rem / 16);
      background-color: #02edef33;
    }

    /deep/ .van-tab__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: calc(8rem / 16) calc(12rem / 16);

      .list_img {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: calc(48rem / 16);
          height: calc(48rem / 16);
          display: block;
        }
      }


      .list_title {
        color: var(--btnsizeColor);
        margin-top: calc(4rem / 16);
        font-size: calc(10rem / 16);
        line-height: calc(10rem / 16);
      }

      .list_money {
        color: var(--btnsizeColor);
        margin-top: calc(4rem / 16);
        font-size: calc(6rem / 16);
      }
    }

    .btn {
      background-color: var(--newBtn);
      color: var(--btnsizeColor);
      font-size: calc(12rem / 16);
      padding: calc(8rem /16) 0;
      border-radius: calc(10rem / 16);
      text-align: center;
      margin-top: calc(16rem / 16);
      line-height: calc(18rem / 16);
    }



    .lists {
      display: flex;
      padding-right: calc(12rem / 16);

      .nodata {
        flex: 1;
        color: var(--btnsizeColor);
        font-size: calc(12rem / 16);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: calc(20rem / 16)
      }
    }
  }

  .gift_list {
    .scroll-container {
      overflow: hidden;
      height: calc(180rem / 16);
      padding: calc(16rem / 16);
      border-radius: calc(20rem / 16);
      background-color: var(--nav-background);
      margin-top: calc(16rem / 16);

      .content {
        height: calc(180rem / 16);
        display: flex;
        flex-direction: column;

        .content_list {
          display: flex;
          align-items: center;
          color: var(--btnsizeColor);
          font-size: calc(12rem / 16);
          margin-bottom: calc(8rem / 16);

          .img {
            width: calc(24rem / 16);
            height: calc(24rem / 16);
          }

          img {
            width: calc(24rem / 16);
            height: calc(24rem / 16);
            display: block;
          }

          .username {
            margin: 0 calc(16rem / 16);
          }

          .send {
            margin-right: calc(16rem / 16);
          }
        }
      }
    }
  }

  /deep/ .van-overlay {
    right: 0;
    display: flex;
    align-items: flex-end;
  }

  .wrappers {
    width: 100%;
    padding: calc(16rem / 16);

    &_title {
      background-color: var(--bg);
      color: var(--theme);
      text-align: center;
      padding: calc(6rem / 16) 0;
      border-radius: calc(10rem / 16);
    }

    .block {
      background-color: var(--bg);
      margin: calc(16rem / 16) 0;
      margin-bottom: 0;
      border-radius: calc(10rem / 16);
      padding: calc(16rem / 16);

      &_img {
        display: flex;
        justify-content: center;

        .imgs {
          display: inline-block;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: calc(16rem / 16) calc(34rem / 16);
          border: 1px solid #363637;
          border-radius: calc(10rem / 16);

          img {
            width: calc(60rem / 16);
            height: calc(60rem / 16);
            display: block;
          }

          .title {
            color: var(--sizeColor);
            font-size: calc(16rem / 16);
            text-align: center;
            margin-top: calc(12rem / 16);
            margin-bottom: calc(8rem / 16);
          }

          .amount {
            text-align: center;
            color: #BBBBBB;
            font-size: calc(12rem / 16);
          }
        }
      }

      &_cont {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cont_left {
          color: var(--theme);
          font-size: calc(12rem / 16);
        }

        .cont_value {
          /deep/ .van-stepper__input {
            color: var(--theme);
          }

          .input {
            width: calc(70rem / 16);
            color: var(--theme);
            font-size: calc(12rem / 16);
            background-color: transparent;
            border-color: transparent;
            border-bottom: 1px solid rgb(118, 118, 118), ;
          }
        }
      }

      .btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--newBtn);
        color: var(--btnsizeColor);
        padding: calc(8rem / 16) 0;
        border-radius: calc(10rem / 16);
        margin-top: calc(16rem / 16);
      }
    }
  }
}

.flex_reverse {
  flex-direction: row-reverse;
}
</style>
